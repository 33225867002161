// TrainingPlanPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box, Tab, Tabs, Typography, TextField, Button, Grid,
    Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import { format, parseISO, isValid } from 'date-fns';
import PoolIcon from '@mui/icons-material/Pool';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';


function TrainingPlanPage() {

    const apiUrl = process.env.REACT_APP_API_URL; // Access the environment variable
    const [errorMessage, setErrorMessage] = useState("");
    const [exportFormat, setExportFormat] = useState('pdf');


    const [apiResponseHtml, setApiResponseHtml] = useState(""); // Changed to store HTML response

    const [currentSubTabIndex, setCurrentSubTabIndex] = useState(0);
     // Distance and time state variables
    const [swimDistance, setSwimDistance] = useState("");
    const [swimTime, setSwimTime] = useState(null); // Use Date object or null for initialization
    const [swimPace, setSwimPace] = useState(null); // Use Date object or null for initialization
    const [bikeDistance, setBikeDistance] = useState("");
    const [bikeTime, setBikeTime] = useState(null); // Use Date object or null for initialization
    const [bikePace, setBikePace] = useState(null); // Use Date object or null for initialization
    const [runDistance, setRunDistance] = useState("");
    const [runTime, setRunTime] = useState(null); // Use Date object or null for initialization
    const [runPace, setRunPace] = useState(null); // Use Date object or null for initialization

    // Additional state variables
    const [distanceUnit, setDistanceUnit] = useState("km"); // State variable for distance unit
    const [trainingPeriod, setTrainingPeriod] = useState("16-weeks"); // State variable for distance unit

    const [longTrainingDay, setLongTrainingDay] = useState("Saturday");
    const [dynamicLink, setDynamicLink] = useState("");

    // Transition time state variables
    //const [transition1, setTransition1] = useState(null); // Use Date object or null for initialization
    //const [transition2, setTransition2] = useState(null); // Use Date object or null for initialization

    const [transition1, setTransition1] = useState("0:00:00");
    const [transition2, setTransition2] = useState("0:00:00");

    const [raceDate, setRaceDate] = useState(new Date().toISOString().slice(0, 10)); // default to today's date in YYYY-MM-DD format



    const [distanceType, setDistanceType] = useState('');

    const handleDistanceSelection = (event) => {
        const value = event.target.value;
        setDistanceType(value);
        if (value === 'longDistance') {
            setRunDistance(42.2); // Marathon
            setBikeDistance(180); // Typical full Ironman bike distance in kilometers
            setSwimDistance(3.8); // Full Ironman swim distance in kilometers
        }
        if (value === 'middleDistance') {
            setRunDistance(21.1); // Marathon
            setBikeDistance(90); // Typical full Ironman bike distance in kilometers
            setSwimDistance(1.9); // Full Ironman swim distance in kilometers
        }
    };


    const handleRunDistanceChange = (event) => {
        setRunDistance(event.target.value);
    };

    const [marathonDistance, setMarathonDistance] = useState(42.2);
    const [halfMarathonDistance, setHalfMarathonDistance] = useState(21.1);

    const parseDate = (dateString) => {
        if (typeof dateString === 'string') {
          return parseISO(dateString);
        }
        return dateString;
    };

    const handleTimeChange = (newValue, setter) => {
    if (newValue && isValid(parseDate(newValue))) {
        const formattedTime = format(parseDate(newValue), "HH:mm:ss");
        setter(formattedTime);
    } else {
        setter(null);
    }
    };

    const handlePaceChange = (newValue, setter) => {
    if (newValue && isValid(parseDate(newValue))) {
        const formattedPace = format(parseDate(newValue), "mm:ss");
        setter(formattedPace);
    } else {
        setter(null);
    }
    };

    // Update these handlers
    const handleSwimTimeChange = (newValue) => handleTimeChange(newValue, setSwimTime);
    const handleSwimPaceChange = (newValue) => handlePaceChange(newValue, setSwimPace);
    const handleBikeTimeChange = (newValue) => handleTimeChange(newValue, setBikeTime);
    const handleRunTimeChange = (newValue) => handleTimeChange(newValue, setRunTime);
    const handleRunPaceChange = (newValue) => handlePaceChange(newValue, setRunPace);
    
    
    const handleBikePaceChange = (event) => {
        //setBikePace(event.target.value);
        setBikePace(event.target.value);
    };

    const handleDistanceUnitChange = (event) => {
        setDistanceUnit(event.target.value);
    };

    const handleTrainingPeriodChange = (event) => {
        setTrainingPeriod(event.target.value);
    };

    const handleLongTrainingDayChange = (event) => {
        setLongTrainingDay(event.target.value);
    };


    const handleSubTabChange = (event, subTabIndex) => {
        setCurrentSubTabIndex(subTabIndex);
        setSwimDistance("");
        setSwimTime(null);
        setSwimPace(null);
        setBikeDistance("");
        setBikeTime(null);
        setBikePace(null);
        setRunDistance("");
        setRunTime(null);
        setRunPace(null);
        setTrainingPeriod("16-weeks");
        setDistanceUnit("km"); // Reset distance unit
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Construct formData here
            const formData = {
                swimDistance,
                swimTime: swimTime || null,
                swimPace: swimPace || null,
                bikeDistance,
                bikeTime: bikeTime || null,
                bikePace: bikePace || null,
                runDistance,
                runTime: runTime || null,
                runPace: runPace || null,
                distanceUnit,
                transition1: transition1 || null,
                transition2: transition2 || null
            };

            // Call the first API (handleTriathlonSubmit)
            //await handleTriathlonSubmit(formData); // pass formData if needed
            await handleTriathlonSubmit();
            // Call the second API (handleTrainingPlanSubmit)
            await handleTrainingPlanSubmit(); // pass formData

             // Update the URL with the new parameters and current tab
            const linkData = { 
                ...formData, 
                distanceType: currentSubTabIndex === 0 ? distanceType : undefined,  // Include distanceType only for Triathlon
                tab: currentSubTabIndex === 0 ? "Triathlon" : "Run"
            };
            const queryParams = new URLSearchParams(linkData).toString();
            navigate(location.pathname + "?" + queryParams, { replace: true });
        } catch (error) {
            console.error("Error during API requests:", error);
            setErrorMessage("Error: " + error.message);
        }
    };
    
    const navigate = useNavigate(); // Get the navigate function
    const location = useLocation();

    useEffect(() => {
        // Extract query parameters from the URL
        const params = new URLSearchParams(location.search);

        // Update state variables based on query parameters only if the values are not already set
        if (params.toString()) {
            setSwimDistance(swimDistance || params.get("swimDistance") || "");
            setSwimTime(swimTime || params.get("swimTime") || null);
            setSwimPace(swimPace || params.get("swimPace") || null);
            setBikeDistance(bikeDistance || params.get("bikeDistance") || "");
            setBikeTime(bikeTime || params.get("bikeTime") || null);
            setBikePace(bikePace || params.get("bikePace") || null);
            setRunDistance(runDistance || params.get("runDistance") || "");
            setRunTime(runTime || params.get("runTime") || null);
            setRunPace(runPace || params.get("runPace") || null);
            setDistanceUnit(distanceUnit || params.get("distanceUnit") || "km");
            setDistanceUnit(longTrainingDay || params.get("longTrainingDay") || "16-weeks");
            setRaceDate(raceDate || params.get("raceDate") || new Date().toISOString().slice(0, 10));
            setLongTrainingDay(longTrainingDay || params.get("longTrainingDay") || "Saturday");

            // Update tab based on query parameter or default to Triathlon
            const tabParam = params.get("tab") || "Triathlon";
            setCurrentSubTabIndex(tabParam === "Triathlon" ? 0 : 1); 
            
            setDistanceType(distanceType || params.get("distanceType") || '');

            // Update the URL to include the query parameters
            navigate(location.pathname + "?" + params.toString(), { replace: true });
        }
    }, [location.search]); // This effect runs only when location.search changes


    
  const parseTimeToTicks = (timeString) => {
    if (!timeString) return null;
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0);
    return date;
  };

  const parsePaceToTicks = (timeString) => {
    if (!timeString) return null;
    const [minutes, seconds] = timeString.split(':').map(Number);
    const date = new Date();
    date.setMinutes(minutes, seconds, 0);
    return date;
  };

  const formatDateToTimeString = (date_str) => {
    if (!date_str) return null;
    const date = new Date(date_str);
    const hours = date.getHours().toString().padStart(2, '0');  // Local hours
    const minutes = date.getMinutes().toString().padStart(2, '0');  // Local minutes
    const seconds = date.getSeconds().toString().padStart(2, '0');  // Local seconds
    return `${hours}:${minutes}:${seconds}`;
};

  const formatPaceToString = (date_str) => {
    if (!date_str) return null;
    const date = new Date(date_str);
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

    const handleTriathlonSubmit = async () => {
        try {
            // Create form data object with the latest state values
            const formatTicksToTimeString = (ticks) => {
              return new Date(ticks).toISOString().substr(11, 8);
            };
            const formatTicksToPaceString = (ticks) => {
              return new Date(ticks).toISOString().substr(11, 8);
            };
           
              console.log("runtime before: ", runTime);
    
              const formData = {
                swimDistance,
                swimTime: swimTime || null,
                swimPace: swimPace || null,
                bikeDistance,
                bikeTime: bikeTime || null,
                bikePace: bikePace || null,
                runDistance,
                runTime: runTime || null,
                runPace: runPace || null,
                distanceUnit,
                trainingPeriod,
                transition1: transition1 || null,
                transition2: transition2 || null
            };
    
          const params = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [
                key, 
                value !== null ? value : "null"
              ])
          );
            params.swimPace = params.swimPace === "null" || !(params.swimPace instanceof Date) 
                ? params.swimPace 
                : formatPaceToString(params.swimPace);
            params.swimTime = params.swimTime === "null" || !(params.swimTime instanceof Date) 
                ? params.swimTime 
                : formatDateToTimeString(params.swimTime);
            params.bikeTime = params.bikeTime === "null" || !(params.bikeTime instanceof Date) 
                ? params.bikeTime 
                : formatDateToTimeString(params.bikeTime);
            params.runTime = params.runTime === "null" || !(params.runTime instanceof Date) 
                ? params.runTime 
                : formatDateToTimeString(params.runTime);
            params.runPace = params.runPace === "null" || !(params.runPace instanceof Date) 
                ? params.runPace 
                : formatPaceToString(params.runPace);
            //params.runTime = params.runTime === "null" ? params.runTime : formatDateToTimeString(params.runTime);
            console.log(params);
    
            // Make the API call
            const response = await axios.get(`${apiUrl}/pace-calc`, { params });
            
    
            // Update state with response data or retain existing values if response is missing data
            setBikeDistance(response.data.bikeDistance || bikeDistance);
            setBikeTime(bikeTime ? bikeTime : parseTimeToTicks(response.data.bikeTime));
            setBikePace(response.data.bikePace || bikePace);
            setSwimDistance(response.data.swimDistance || swimDistance);
            setSwimTime(swimTime ===null ? swimTime : formatDateToTimeString(swimTime));
            setSwimPace(swimPace ===null ? swimPace : formatPaceToString(swimPace));
            setRunDistance(response.data.runDistance || runDistance);
            setRunTime(runTime ===null ? runTime : formatDateToTimeString(runTime));
            setRunTime(parseTimeToTicks(response.data.runTime));
            setRunPace(runPace ===null ? runPace : formatPaceToString(runPace));
            setRunPace(runPace !==null ? runPace : parsePaceToTicks(response.data.runPace));
            setDistanceUnit(response.data.distanceUnit || distanceUnit);
            setTransition1(transition1 ? transition1 : parseTimeToTicks(response.data.transition1));
            setTransition2(transition2 ? transition2 : parseTimeToTicks(response.data.transition2));
    
            setSwimTime(parseTimeToTicks(response.data.swimTime));
            setSwimPace(swimPace ? swimPace : parsePaceToTicks(response.data.swimPace));
    
        } catch (error) {
            console.error("API error:", error);
            setErrorMessage("Error: " + error.message);
        }
    
        console.log("Triathlon form submitted!");
    };
    
    const handleTrainingPlanSubmit = async () => {
        const formData = {
            runDistance, 
            runTime, 
            runPace, 
            bikeDistance,
            bikeTime,
            bikePace,
            swimDistance,
            swimTime,
            swimPace,
            distanceUnit,
            raceDate,
            longTrainingDay,
            trainingPeriod
        };

        try {
            const params = Object.fromEntries(
                Object.entries(formData).map(([key, value]) => [key, value === null ? "" : value])
            );
            const response = await axios.get(`${apiUrl}/training-plan-html`, { params });
            setApiResponseHtml(response.data); 

            // Update the URL with the new parameters and current tab
            const linkData = { 
                ...formData, 
                distanceType: currentSubTabIndex === 0 ? distanceType : undefined,  // Include distanceType only for Triathlon
                tab: currentSubTabIndex === 0 ? "Triathlon" : "Run"
            };
            const queryParams = new URLSearchParams(linkData).toString();
            navigate(location.pathname + "?" + queryParams, { replace: true });
        } catch (error) {
            console.error("Error during API request:", error);
            setErrorMessage("Error: " + error.message);
        }
    };

    const downloadTrainingPlanFile = async () => {
        if (!['pdf', 'html'].includes(exportFormat)) {
            setErrorMessage('Please select a format to export.');
            return;
        }
        try {
            const transformedSwimTime = swimTime === "null" ? swimTime : formatDateToTimeString(swimTime);
            const transformedSwimPace = swimPace === "null" ? swimPace : formatPaceToString(swimPace);
            const transformedBikeTime = bikeTime === "null" ? bikeTime : formatDateToTimeString(bikeTime);
            const transformedRunTime = runTime === "null" ? runTime : formatDateToTimeString(runTime);
            const transformedRunPace = runPace === "null" ? runPace : formatPaceToString(runPace);

            const response = await axios.get(`${apiUrl}/export-file`, {
                params: {
                    swimDistance, 
                    swimTime: transformedSwimTime, 
                    swimPace: transformedSwimPace, 
                    bikeDistance, 
                    bikeTime, 
                    bikePace, 
                    runDistance, 
                    runTime: transformedRunTime, 
                    runPace: transformedRunPace, 
                    distanceUnit,
                    trainingPeriod,
                    raceDate, 
                    longTrainingDay, 
                    format: exportFormat
                },
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `TrainingPlan.${exportFormat}`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error(`Error downloading ${exportFormat} file:`, error);
            setErrorMessage(`Failed to download ${exportFormat} file. Please try again.`);
        }
    };

    return (
        <div>
            <Box sx={{ display: "flex" , flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center"}}>
                <Tabs
                    value={currentSubTabIndex}
                    onChange={handleSubTabChange}
                    orientation="horizontal"
                    sx={{ borderRight: 1, borderColor: "divider" }}
                >
                    <Tab label="Triathlon" />
                    <Tab label="Run" />
                </Tabs>
                
                {currentSubTabIndex === 0 && (
                    <Box sx={{ p: 3, maxWidth: 1200, mx: 'auto' }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={4}>
                                {/* Distance and Date Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                                        <Typography variant="h5" component="div">
                                            Race Details
                                        </Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" gutterBottom>Distance</Typography>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="distance-label">Distance</InputLabel>
                                                    <Select
                                                        labelId="distance-label"
                                                        value={distanceType}
                                                        onChange={handleDistanceSelection}
                                                        label="Distance"
                                                    >
                                                        <MenuItem value="longDistance">Long Distance</MenuItem>
                                                        <MenuItem value="middleDistance">Middle Distance</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" gutterBottom>Distance Unit</Typography>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="distance-unit-label">Distance Unit</InputLabel>
                                                    <Select
                                                        labelId="distance-unit-label"
                                                        value={distanceUnit}
                                                        onChange={handleDistanceUnitChange}
                                                        label="Distance Unit"
                                                    >
                                                        <MenuItem value="km">km</MenuItem>
                                                        {/* <MenuItem value="mi">miles</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" gutterBottom>Training Period</Typography>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="training-period-label">Training Period</InputLabel>
                                                    <Select
                                                        labelId="training-period-label"
                                                        value={trainingPeriod}
                                                        onChange={handleTrainingPeriodChange}
                                                        label="Training Period"
                                                    >
                                                        <MenuItem value="16-weeks">16 Weeks</MenuItem>
                                                        <MenuItem value="24-weeks">24 Weeks</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" gutterBottom>Date of Race</Typography>
                                                <TextField
                                                    label="Race Date"
                                                    type="date"
                                                    value={raceDate}
                                                    onChange={(event) => setRaceDate(event.target.value)}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" gutterBottom>Day of Long Training</Typography>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="long-training-day-label">Long Training Day</InputLabel>
                                                    <Select
                                                        labelId="long-training-day-label"
                                                        value={longTrainingDay}
                                                        onChange={handleLongTrainingDayChange}
                                                        label="Long Training Day"
                                                    >
                                                        <MenuItem value="Monday">Monday</MenuItem>
                                                        <MenuItem value="Tuesday">Tuesday</MenuItem>
                                                        <MenuItem value="Wednesday">Wednesday</MenuItem>
                                                        <MenuItem value="Thursday">Thursday</MenuItem>
                                                        <MenuItem value="Friday">Friday</MenuItem>
                                                        <MenuItem value="Saturday">Saturday</MenuItem>
                                                        <MenuItem value="Sunday">Sunday</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                {/* Time and Pace Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                                        <Typography variant="h5" component="div">
                                            Pace Details
                                        </Typography>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={5}>
                                                <Typography variant="h6" gutterBottom>Target Time</Typography>
                                                <Stack spacing={2}>
                                                    
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <PoolIcon />
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker
                                                            label="Swim (hh:mm:ss)"
                                                            value={swimTime}
                                                            onChange={handleSwimTimeChange}
                                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                                            ampm={false} // Disable AM/PM
                                                            views={['hours', 'minutes', 'seconds']}
                                                            format="HH:mm:ss" // Ensure the format is hh:mm:ss
                                                            />
                                                        </LocalizationProvider>
                                                    </Stack>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <DirectionsBikeIcon />
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker
                                                                label="Bike (hh:mm:ss)"
                                                                value={bikeTime}
                                                                onChange={handleBikeTimeChange}
                                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                                ampm={false} // Disable AM/PM
                                                                views={['hours', 'minutes', 'seconds']}
                                                                format="HH:mm:ss" // Ensure the format is hh:mm:ss
                                                            />
                                                        </LocalizationProvider>
                                                    </Stack>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <DirectionsRunIcon />
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker
                                                                label="Run (hh:mm:ss)"
                                                                value={runTime}
                                                                onChange={handleRunTimeChange}
                                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                                ampm={false} // Disable AM/PM
                                                                views={['hours', 'minutes', 'seconds']}
                                                                format="HH:mm:ss" // Ensure the format is hh:mm:ss
                                                            />
                                                        </LocalizationProvider>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            {/* OR Section */}
                                            <Grid item xs={12} md={2} container alignItems="center" justifyContent="center">
                                                <Typography variant="h4" align="center">OR</Typography>
                                            </Grid>
                                            {/* Pace Section */}
                                            <Grid item xs={12} md={5}>
                                                <Typography variant="h6" gutterBottom>Target Pace</Typography>
                                                <Stack spacing={2}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <PoolIcon />
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker
                                                                label="Swim (mm:ss)"
                                                                value={swimPace}
                                                                onChange={handleSwimPaceChange}
                                                                views={['minutes', 'seconds']} format="mm:ss"
                                                                renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" fullWidth />}
                                                                ampm={false} // Disable AM/PM
                                                                minutesStep={1}
                                                            />
                                                            
                                                        </LocalizationProvider>
                                                    </Stack>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <DirectionsBikeIcon />
                                                        <TextField
                                                            label="Bike (km/h)"
                                                            type="number"
                                                            value={bikePace}
                                                            onChange={handleBikePaceChange}
                                                            inputProps={{ step: "0.2" }} // Allows decimal input
                                                            variant="outlined"
                                                            margin="normal"
                                                            //fullWidth
                                                        />
                                                    </Stack>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <DirectionsRunIcon />
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker
                                                                label="Run (mm:ss)"
                                                                value={runPace}
                                                                onChange={handleRunPaceChange}
                                                                views={['minutes', 'seconds']} format="mm:ss"
                                                                renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" fullWidth />}
                                                                ampm={false} // Disable AM/PM
                                                                minutesStep={1}
                                                            />
                                                        </LocalizationProvider>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                {/* Action Buttons */}
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2} justifyContent="space-between">
                                        <Button variant="contained" type="submit">Generate plan</Button>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                                <InputLabel id="export-format-label">Export As</InputLabel>
                                                <Select
                                                    labelId="export-format-label"
                                                    value={exportFormat}
                                                    onChange={(event) => setExportFormat(event.target.value)}
                                                    label="Export As"
                                                >
                                                    <MenuItem value="pdf">PDF</MenuItem>
                                                    {/* <MenuItem value="html">HTML</MenuItem> */}
                                                </Select>
                                            </FormControl>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    if (['pdf', 'html'].includes(exportFormat)) {
                                                        downloadTrainingPlanFile(exportFormat);
                                                    } else {
                                                        setErrorMessage('Please select a format to export.');
                                                    }
                                                }}
                                            >
                                                Export
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                        {/* Display the API response HTML content */}
                        <Box sx={{ mt: 2, ml: 4 }} dangerouslySetInnerHTML={{ __html: apiResponseHtml }} />
                        <Box id="message" sx={{ color: 'red', mt: 2, ml: 4 }}>
                            {errorMessage}
                        </Box>
                        {/* Display the dynamic link */}
                        {dynamicLink}
                    </Box>
                )}

                {currentSubTabIndex === 1 && (
                    <Box sx={{ p: 3, maxWidth: 1200, mx: 'auto' }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={4}>
                                {/* Distance and Date Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                                        <Typography variant="h5" component="div">
                                            Race Details
                                        </Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" gutterBottom>Distance</Typography>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="distance-label">Distance</InputLabel>
                                                    <Select
                                                        labelId="distance-label"
                                                        id="distance"
                                                        value={runDistance}
                                                        onChange={handleRunDistanceChange}
                                                        label="Distance"
                                                    >
                                                        <MenuItem value={marathonDistance}>Marathon</MenuItem>
                                                        <MenuItem value={halfMarathonDistance}>Half-marathon</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" gutterBottom>Distance Unit</Typography>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="distance-unit-label">Distance Unit</InputLabel>
                                                    <Select
                                                        labelId="distance-unit-label"
                                                        value={distanceUnit}
                                                        onChange={handleDistanceUnitChange}
                                                        label="Distance Unit"
                                                    >
                                                        <MenuItem value="km">km</MenuItem>
                                                        {/* <MenuItem value="mi">miles</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" gutterBottom>Date of Race</Typography>
                                                <TextField
                                                    label="Race Date"
                                                    type="date"
                                                    value={raceDate}
                                                    onChange={(event) => setRaceDate(event.target.value)}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" gutterBottom>Day of Long Training</Typography>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="long-training-day-label">Long Training Day</InputLabel>
                                                    <Select
                                                        labelId="long-training-day-label"
                                                        value={longTrainingDay}
                                                        onChange={handleLongTrainingDayChange}
                                                        label="Long Training Day"
                                                    >
                                                        <MenuItem value="Monday">Monday</MenuItem>
                                                        <MenuItem value="Tuesday">Tuesday</MenuItem>
                                                        <MenuItem value="Wednesday">Wednesday</MenuItem>
                                                        <MenuItem value="Thursday">Thursday</MenuItem>
                                                        <MenuItem value="Friday">Friday</MenuItem>
                                                        <MenuItem value="Saturday">Saturday</MenuItem>
                                                        <MenuItem value="Sunday">Sunday</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                {/* Time and Pace Section */}
                                <Grid item xs={12}>
                                    <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                                        <Typography variant="h5" component="div">
                                            Pace Details
                                        </Typography>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={5}>
                                                <Typography variant="h6" gutterBottom>Target Time</Typography>
                                                <Stack spacing={2}>
                                                    <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
                                                        <DirectionsRunIcon />
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker
                                                                label="Run (hh:mm:ss)"
                                                                value={runTime}
                                                                onChange={handleRunTimeChange}
                                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                                ampm={false} // Disable AM/PM
                                                                views={['hours', 'minutes', 'seconds']}
                                                                format="HH:mm:ss" // Ensure the format is hh:mm:ss
                                                            />
                                                        </LocalizationProvider>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            {/* OR Section */}
                                            <Grid item xs={12} md={2} container alignItems="center" justifyContent="center">
                                                <Typography variant="h4" align="center">OR</Typography>
                                            </Grid>
                                            {/* Pace Section */}
                                            <Grid item xs={12} md={5}>
                                                <Typography variant="h6" gutterBottom>Target Pace</Typography>
                                                <Stack spacing={2}sx={{ width: '100%' }}>
                                                    <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
                                                        <DirectionsRunIcon />
                                                        <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ flexGrow: 1 }}>
                                                            <TimePicker
                                                                label="Run (mm:ss)"
                                                                value={runPace}
                                                                onChange={handleRunPaceChange}
                                                                views={['minutes', 'seconds']} format="mm:ss"
                                                                renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" fullWidth />}
                                                                ampm={false} // Disable AM/PM
                                                            />
                                                        </LocalizationProvider>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                {/* Action Buttons */}
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2} justifyContent="space-between">
                                        <Button variant="contained" type="submit">Generate plan</Button>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                                <InputLabel id="export-format-label">Export As</InputLabel>
                                                <Select
                                                    labelId="export-format-label"
                                                    value={exportFormat}
                                                    onChange={(event) => setExportFormat(event.target.value)}
                                                    label="Export As"
                                                >
                                                    <MenuItem value="pdf">PDF</MenuItem>
                                                    {/* <MenuItem value="html">HTML</MenuItem> */}
                                                </Select>
                                            </FormControl>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    if (['pdf', 'html'].includes(exportFormat)) {
                                                        downloadTrainingPlanFile(exportFormat);
                                                    } else {
                                                        setErrorMessage('Please select a format to export.');
                                                    }
                                                }}
                                            >
                                                Export
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                        {/* Display the API response HTML content */}
                        <Box sx={{ mt: 2, ml: 4 }} dangerouslySetInnerHTML={{ __html: apiResponseHtml }} />
                        <Box id="message" sx={{ color: 'red', mt: 2, ml: 4 }}>
                            {errorMessage}
                        </Box>
                        {/* Display the dynamic link */}
                        {dynamicLink}
                    </Box>
                )}
            </Box>
        </div>
    );
}

export default TrainingPlanPage;