// HomePage.js
import React from 'react';
import {
    Typography, Grid, Box
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import Link from '@mui/material/Link';

function HomePage() {
    return (
        <div>
            <Box sx={{ p: 3 }}>

                {/* About the App Section */}
                <Typography variant="h4" gutterBottom>About TriMate</Typography>
                <Typography variant="body1" gutterBottom>
                    TriMate is your go-to companion for triathlon training. Whether you're preparing for your first race or aiming to break personal records, our app offers personalized tools to enhance your training experience.
                </Typography>

                {/* Features Overview */}
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    {/* Training Time Estimation */}
                    <Grid item xs={12} md={4}>
                        <Link href="https://trim8.com/pace-calculation" target="_blank" underline="none">
                            <Card>
                                <CardContent>
                                    <AccessTimeIcon fontSize="large" />
                                    <Typography variant="h6">Race Time Estimation</Typography>
                                    <Typography variant="body2">
                                        Race Time Estimation is a versatile tool designed to:
                                        <ol>
                                            <li>
                                                Predict your race time based on your estimated pace.
                                            </li>
                                            <li>
                                                Determine the pace necessary to achieve your target race time.
                                            </li>
                                        </ol>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>

                    {/* Training Plans */}
                    <Grid item xs={12} md={4}>
                        <Link href="https://trim8.com/training-plan" target="_blank" underline="none">
                            <Card>
                                <CardContent>
                                    <DirectionsRunIcon fontSize="large" />
                                    <Typography variant="h6">Training Plan</Typography>
                                    <Typography variant="body2">
                                        Access tailored training plans for triathlon and running, with upcoming expansions to swimming and cycling. The plans are designed to suit athletes at all levels, from beginners to advanced.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>

                    {/* Nutrition Plan */}
                    <Grid item xs={12} md={4}>
                        <Link href="https://trim8.com/nutrition-plan" target="_blank" underline="none">
                            <Card>
                                <CardContent>
                                    <RestaurantIcon fontSize="large" />
                                    <Typography variant="h6">Nutrition Plan</Typography>
                                    <Typography variant="body2">
                                        Get customized nutrition plans to fuel your training and races. Our plans are designed to help you optimize your performance and recovery with the right balance of macronutrients and hydration strategies.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
                  {/* Pose Estimation */}
                  <Grid item xs={12} md={4}>
                      <Link href="https://trim8.com/pose-estimation" target="_blank" underline="none">
                          <Card>
                              <CardContent>
                                  <VisibilityIcon fontSize="large" />
                                  <Typography variant="h6">Pose Estimation</Typography>
                                  <Typography variant="body2">
                                      Enhance your running form with advanced pose estimation technology. Receive real-time feedback on your running style to boost efficiency and minimize injury risks. Soon, this feature will also include pose estimation for cycling, further broadening its capabilities.
                                  </Typography>
                              </CardContent>
                          </Card>
                      </Link>
                  </Grid>

                {/* How to Use the App */}
                {/* <Box sx={{ marginTop: 4 }}>
                <Typography variant="h5">Getting Started with TriMate</Typography>
                <Typography variant="body1" gutterBottom>Download the App: "Available on iOS and Android. Download now to start your triathlon journey."</Typography>
                <Typography variant="body1" gutterBottom>Create Your Profile: "Tell us about your triathlon goals and experience level."</Typography>
                <Typography variant="body1">Explore Features: "Dive into our training plans, time estimations, and pose analysis tools."</Typography>
              </Box>*/}

                {/* Work in Progress Section */}
                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h5">Features on the Horizon</Typography>
                    <Typography variant="body1">
                        The application aims to evolve to bring you the best in triathlon training. Stay tuned for upcoming features, including:
                        <ul>
                            <li>Swimming and cycling training plans</li>
                            <li>Supplementary exercises</li>
                            <li>Race prediction based on current performance</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1">
                        Your feedback helps the application grow. Let me know what features you'd like to see! Feel free to send your suggestions to{" "}
                        <Link href="mailto:contact.trimate@gmail.com" target="_blank">
                            contact.trimate@gmail.com
                        </Link>.
                    </Typography>
                </Box>

                {/* Footer */}
                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="body2" color="textSecondary">
                        Disclaimer: The training programs and pose estimation provided by this app are based on personal experiences and AI models. While they have been effective for me, it's important to apply common sense when following them. Always listen to your body and consult with a healthcare professional if necessary. For pose estimation, keep in mind that it's an AI-driven feature and may not always be accurate. Review the video analysis carefully to ensure the results align with your observations and needs.
                    </Typography>
                    {/* Add other footer content here */}
                </Box>
            </Box>
        </div>
    );
}

export default HomePage;
